.scroll {
  overflow-y: auto !important;
  max-height: 650px !important;
}
.box {
  margin-top: 10px !important;
}
.col-8.col-lg-3.col-md-3.col-sm-4.pad-rm {
  padding: 0 !important;
}
.row.text-center.d-flex.justify-content-center.abc-pb {
  text-align: center;
  width: 100%;
  margin-top: 17px;
}

.de-nn.col-sm-4 {
  padding: 0 !important;
}

button.bestaply {
  background: #380be2;
  color: #fff;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  width: 50%;
  border: 0;
}

button.bestbtn {
  background: #2dab0e;
  color: #fff;
  width: 50%;
  border: none;
  padding: 7px;
  margin-top: 15px;
  margin-bottom: 10px;
}

button.dangerCl {
  background: rgb(161, 37, 37);
  color: #fff;
  width: 50%;
  border: none;
  padding: 7px;
  margin-top: 15px;
  margin-bottom: 10px;
}

button.bestbtnss {
  background: #1b4377;
  color: #fff;
  width: 35%;
  border: none;
  /* padding: 7px; */
  margin-top: 15px;
  margin-bottom: 10px;
}
.css-p4tgvk-sortOptions{
  z-index: 10 !important;
}
@media only screen and (max-width: 580px){
  .css-p4tgvk-sortOption{
    right: 28px !important;
  }
}


.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #0b6aff;
  color: #343a40;
  height: 60px;
  font-size: 15px;
  letter-spacing: 0.05rem;
}

.dataSearch {
  margin-top: 1px;
}

.datasearch .searchbox {
  min-width: 400px;
  border-radius: 30px;
  padding: 5px 16px 5px 30px;
}

.bg-header {
  background-color: #ff4500;
}

body {
  background-color: #f9f9f9;
}

footer {
  background-color: #f2f2f2;
  padding: 25px;
}
.navbar .css-1mwoafl-MicIcon {
  right: 25px;
}
.navbar .css-dmx3ll-InputIcon-left {
  top: 10px;
}
.navbar .css-dmx3ll-InputIcon-left svg.search-icon {
  fill: #595959;
}
.navbar {
  height: auto;
}
.marginBottom .css-149suws-Card {
  min-width: 245px;
  max-width: 245px;
  font-family: WhitneySSm, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  height: 320px;
  margin: 10px 10px;
}
.marginBottom .css-149suws-Card:hover {
  text-decoration: unset;
}
.css-1ytwrls-Flex-rightLabel.e1hwwpu60 {
  margin-top: 10px;
}
.bestbtnss {
  display: none;
}

.col-8.col-lg-3.col-md-3.col-sm-8.tab-setup-left {
  padding: 0;
}
.navbar-collapse .abc-pb {
  display: none !important;
}
.left-side-fliter {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.box ul label {
  margin: 0px;
}
.box ul label::before {
  width: calc(11px + 1px);
  height: 12px;
}
.box ul label::after {
  left: calc(1px + 10px / 5);
  width: calc(12px / 2);
  height: calc(12px / 5);
}
.box ul {
  padding: 0;
}
/* .box ul li {
  min-height: 25px;
} */
.box ul span {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.text-wrap {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-transform: capitalize;
}
.marginBottom span b {
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
}
.carousel-inner {
  background: linear-gradient(to bottom, #ffe400 0%, #ff4500 100%);
  /*    background: #ffffff; */
  /*    background-image: url("https://d2qjxo7zbjilfg.cloudfront.net/images/d379808a-e67d-43c4-ad63-d604a5b93206.png"); */
  background-position: 50%;
  
}
/* .carousel-inner img {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 10px;
  height: 260px;
} */
.carousel-item {
  padding: 0 10px; /* Add padding to create a gap between images */
}

.carousel-image {
  max-width: 100%; /* Ensures image fits within its container */
   /* Maintains aspect ratio */
}
.carousel-control-next-icon {
  margin-left: 130px;
}
.carousel-control-prev-icon {
  margin-right: 130px;
}
.legend {
  color: #ff4500;
  font-weight: 500;
}
.mt-2 {
  display: none;
}
.page-footer .font-bold {
  margin: 0;
}
.pop-box {
  margin: 0px !important;
}

@media only screen and (max-width: 1300px) and (min-width: 1030px) {
  .marginBottom .css-149suws-Card {
    margin: 10px 15px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
  .top-header .navbar.bg-header {
    display: flex;
  }
  .top-header .navbar.bg-header div {
    flex: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .marginBottom .css-149suws-Card {
    margin: 10px 10px !important;
    min-width: 180px !important;
    max-width: 180px !important;
  }
  .new-navset {
    display: none;
  }
  .ps-capitalize {
    font-size: 12px;
  }
  .ps-compare-price {
    font-size: 10px;
  }
  .marginBottom {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .page-footer .title.font-bold {
    font-size: 14px;
  }

  .top-header .navbar.bg-header div {
    flex: auto;
  }
  .MuiList-root .col-md-2.mb-3 {
    width: 100%;
    max-width: 400px;
  }
  .MuiList-root h6 a {
    color: #000;
    font-size: 16px;
    margin: 10px 5px !important;
    display: block;
  }
}

@media only screen and (max-width: 900px) and (min-width: 768px) {
  .datasearch .searchbox {
    width: 100%;
    min-width: 100%;
  }
  .navbar-toggler {
    border-color: rgb(239 239 239 / 0%) !important;
  }
  .css-12v9vzr-container .css-149suws-Card {
    margin: 3px 3px !important;
    width: 48% !important;
    min-width: 180px;
  }
  .new-box {
    display: none;
  }
  .css-p4tgvk-sortOptions{
    height: 50%;
    width: 80%;
  }
  button.bestbtnss {
    position: fixed;
    bottom: 0px;
    z-index: 50;
    left: 0;
    padding: 0px;
    width: 45%;
    margin: 0;
    background: #fff;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    outline: 1px solid #ddd;
  }
  .css-p4tgvk-sortOptions {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 50%;
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 500;
  }
  .bestbtnss .result-icon {
    width: 31px !important;
    padding-right: 3px;
  }
  /* .bestbtnss {
  display: block;
 } */
  .de-nn {
    max-width: 150px;
    background: #f5f5f6;
    height: 72vh;
  }
  .right-box {
    width: 100%;
    max-width: 200px;
  }
  .pad-rm {
    background: #fff;
  }
  .nav-pills .nav-link.active {
    background-color: #fff !important;
    color: #3e4152 !important;
    border-bottom: 1px solid #eaeaec;
  }
  .pad-rm h1 {
    font-size: 16px;
    padding: 15px 15px;
    margin: 0;
    /* text-transform: uppercase; */
    border-bottom: 1px solid #eaeaec;
  }
  .nav-pills .nav-link {
    background: #f5f5f6;
    border-bottom: 1px solid #ccc;
    border-radius: 0px !important;
    font-size: 14px;
    padding: 15px 10px;
    color: #3e4152;
  }
  .pad-rm {
    height: 85vh;
  }
  .navbar-collapse .abc-pb {
    display: block !important;
    background: #fff;
    border-radius: 5px;
  }
  .abc-pb .font-bold a {
    box-shadow: none;
    color: #000;
    display: block;
    padding: 10px 0px;
  }
  .bestaply {
    background: #fd1607 !important;
    width: 100% !important;
  }
  .row.pt-5.mb-3 {
    display: none !important;
  }
  .MuiListItemIcon-root {
    display: none !important;
  }
  .MuiDrawer-paperAnchorBottom {
    top: 30% !important;
  }
  hr.MuiDivider-root {
    display: none;
  }
  .mobileCalender {
    left: 0 !important;
    right: auto !important;
  }
  .DayPickerInput-Overlay {
    left: auto !important;
    right: 0 !important;
  }
  .mt-2 {
    display: block;
  }
  .navbar-light .navbar-toggler {
    background: #fff;
  }
  .mt-2 {
    position: absolute;
    right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .carousel-inner img {
    max-width: 100%;
  }
  .pad-rm,
  .tab-setup-left {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .bg-header .d-flex {
    width: 100%;
  }
  .mt-2 {
    position: absolute;
    right: 10px;
  }
  .navbar-light .navbar-toggler {
    background: #fff;
  }
  .marginBottom {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .MuiList-root h6 a {
    color: #000;
    font-size: 16px;
    margin: 10px 5px !important;
    display: block;
  }
}
@media only screen and (max-width: 768px) and (min-width: 580px) {
  .datasearch .searchbox {
    width: 100%;
    min-width: 100%;
  }
  .navbar-toggler {
    border-color: rgb(239 239 239 / 0%) !important;
  }
  .css-12v9vzr-container .css-149suws-Card {
    margin: 3px 3px !important;
    width: 48% !important;
    min-width: 180px;
  }
  .new-box {
    display: none;
  }
  button.bestbtnss {
    position: fixed;
    bottom: 0px;
    left: 0;
    padding: 0px;
    width: 50%;
    margin: 0;
    background: #fff;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    outline: 1px solid #ddd;
  }
  .css-p4tgvk-sortOptions {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 50%;
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 500;
  }
  .bestbtnss .result-icon {
    width: 31px !important;
    padding-right: 3px;
  }
  .bestbtnss {
    display: block;
  }
  .de-nn {
    max-width: 150px;
    background: #f5f5f6;
    height: 72vh;
  }
  .right-box {
    width: 100%;
    max-width: 200px;
  }
  .pad-rm {
    background: #fff;
  }
  .nav-pills .nav-link.active {
    background-color: #fff !important;
    color: #3e4152 !important;
    border-bottom: 1px solid #eaeaec;
  }
  .pad-rm h1 {
    font-size: 16px;
    padding: 15px 15px;
    margin: 0;
    /* text-transform: uppercase; */
    border-bottom: 1px solid #eaeaec;
  }
  .nav-pills .nav-link {
    background: #f5f5f6;
    border-bottom: 1px solid #ccc;
    border-radius: 0px !important;
    font-size: 14px;
    padding: 15px 10px;
    color: #3e4152;
  }
  .pad-rm {
    height: 85vh;
  }
  .navbar-collapse .abc-pb {
    display: block !important;
    background: #fff;
    border-radius: 5px;
  }
  .abc-pb .font-bold a {
    box-shadow: none;
    color: #000;
    display: block;
    padding: 10px 0px;
  }
  .bestaply {
    background: #fd1607 !important;
    width: 100% !important;
  }
  .row.pt-5.mb-3 {
    display: none !important;
  }
  .MuiListItemIcon-root {
    display: none !important;
  }
  .MuiDrawer-paperAnchorBottom {
    top: 30% !important;
  }
  hr.MuiDivider-root {
    display: none;
  }
  .mobileCalender {
    left: 0 !important;
    right: auto !important;
  }
  .DayPickerInput-Overlay {
    left: auto !important;
    right: 0 !important;
  }
  .mt-2 {
    display: block;
  }
  .navbar-light .navbar-toggler {
    background: #fff;
  }
  .mt-2 {
    position: absolute;
    right: 10px;
  }
  .marginBottom .css-149suws-Card {
    min-width: 170px !important;
    max-width: 170px !important;
  }
}

@media only screen and (max-width: 580px) {
  .datasearch .searchbox {
    width: 100%;
    min-width: 100%;
  }
  .navbar-toggler {
    border-color: rgb(239 239 239 / 0%) !important;
  }
  .css-12v9vzr-container .css-149suws-Card {
    margin: 3px 3px !important;
    width: 48% !important;
    min-width: 180px;
  }
  .new-box {
    display: none;
  }
  button.bestbtnss {
    position: fixed;
    bottom: 0px;
    z-index: 50 !important;
    left: 0;
    padding: 0px;
    width: 50%;
    margin: 0;
    background: #fff;
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    outline: 1px solid #ddd;
  }
  .css-p4tgvk-sortOptions {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 50%;
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 500;
  }
  .bestbtnss .result-icon {
    width: 31px !important;
    padding-right: 3px;
  }
  .bestbtnss {
    display: block;
  }
  .de-nn {
    max-width: 150px;
    background: #f5f5f6;
    height: 72vh;
  }
  .right-box {
    width: 100%;
    max-width: 200px;
  }
  .pad-rm {
    background: #fff;
  }
  .nav-pills .nav-link.active {
    background-color: #fff !important;
    color: #3e4152 !important;
    border-bottom: 1px solid #eaeaec;
  }
  .pad-rm h1 {
    font-size: 16px;
    padding: 15px 15px;
    margin: 0;
    /*text-transform: uppercase; */
    border-bottom: 1px solid #eaeaec;
  }
  .nav-pills .nav-link {
    background: #f5f5f6;
    border-bottom: 1px solid #ccc;
    border-radius: 0px !important;
    font-size: 14px;
    padding: 15px 10px;
    color: #3e4152;
  }
  .pad-rm {
    height: 85vh;
  }
  .navbar-collapse .abc-pb {
    display: block !important;
    background: #fff;
    border-radius: 5px;
  }
  .abc-pb .font-bold a {
    box-shadow: none;
    color: #000;
    display: block;
    padding: 10px 0px;
  }
  .bestaply {
    background: #fd1607 !important;
    width: 100% !important;
  }
  .row.pt-5.mb-3 {
    display: none !important;
  }
  .marginBottom {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .MuiListItemIcon-root {
    display: none !important;
  }
  .MuiDrawer-paperAnchorBottom {
    top: 30% !important;
  }
  hr.MuiDivider-root {
    display: none;
  }
  .mobileCalender {
    left: 0 !important;
    right: auto !important;
  }
  .DayPickerInput-Overlay {
    left: auto !important;
    right: 0 !important;
  }
  .mt-2 {
    display: block;
  }
  .MuiList-root a {
    color: #000;
    font-size: 16px;
    margin: 10px 5px !important;
    display: block;
  }
  .middleInfo h3 {
    font-size: 18px;
  }
  .card .title h3 {
    position: relative;
    top: 35px;
  }
  .card .title {
    display: block;
    width: 100%;
    margin: 0;
  }
  .card .transfIcon {
    width: 45%;
    float: left;
  }
  .card .imagett {
    width: 100%;
    margin: 0;
    float: left;
    text-align: center;
  }
  .card .imgTitle {
    display: block;
    width: 100%;
    float: left;
  }
  .card .imagett svg {
    height: 20px;
  }
  .card .redirectInfo p {
    font-size: 19px;
  }
  .carousel-inner img {
    width: 100%;
    height: 200px;
  }
  .marginBottom {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 480px) and (min-width: 376px) {
  .marginBottom .css-149suws-Card {
    min-width: 266px !important;
  }
}
@media only screen and (max-width: 375px) {
  .de-nn {
    max-width: 135px;
  }
  .bestbtnss {
    font-size: 14px !important;
  }
  .css-p4tgvk-sortOptions {
    font-size: 14px !important;
  }
  .bestbtnss .result-icon {
    width: 22px !important;
  }
  .tab-setup-left {
    padding: 0px !important;
  }
  .marginBottom .css-149suws-Card {
    min-width: 100% !important;
    height: 300px;
  }
  .box ul span {
    font-size: 12px !important;
  }
  select.css-p4tgvk-sortOptions option {
    font-size: 10px !important;
  }
  button.bestbtnss {
    width: 50%;
  }
}
@media only screen and (max-width: 380px) {
  .marginBottom .css-149suws-Card {
    min-width: 100% !important;
  }
}
@media only screen and (max-width: 360px) {
  .right-box {
    max-width: 195px;
  }
}
/* search box */
.css-q2r6ms-IconWrapper-ButtonIconWrapper{
  display: none !important;
}

@media only screen and (max-width: 280px) {
  .logo-img b {
    font-size: 14px;
  }
  .logo-img img {
    width: 50px;
  }
  button.bestbtnss {
    height: 40px;
  }
  .css-p4tgvk-sortOptions {
    height: 40px !important;
  }
  .right-box {
    max-width: 140px;
  }
  .de-nn {
    max-width: 110px;
  }
}
.container.modelct {
  display: flex;
  justify-content: center;
  height: 100vh;
}
/* Change for search remove align-items class */
.card {
  position: relative;
  left: auto;
  right: auto;
  /* margin: auto; */
  justify-content: center !important;
  /* align-items: center !important; */
  display: flex !important;
  max-width: 900px;
}
.buttomBoder {
  width: 93%;
  display: block;
  border-bottom: 1px solid #f1f1f3;
  margin: auto 0;
}
.middleInfo {
  text-align: center;
  margin-top: 1.87rem;
}
.middleInfo p {
  font-size: 18px;
  line-height: 24px;
  color: #666666;
  display: inline-block;
  padding: 0.62rem 0.62rem 0 0.62rem;
  font-weight: 400;
}
.rupeeIcon {
  text-align: center;
  margin-top: 1.56rem;
}
.redirectInfo {
  text-align: center;
}
.centerIcons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}
.transfIcon {
  background: url("./PS_logo150_100.png") no-repeat;
  object-fit: contain;
  width: 164px;
  height: 103px;
}
.imagett {
  width: 100%;
  text-align: center;
}


@media only screen and (max-width: 767px) {
  .imagett {
    position: absolute;
    bottom: 50px; /* Adjust this value as needed */
    left: 0;
    right: 0;
  }
}
.imgTitle {
  display: flex;
  align-items: center;
}
.imgTitle {
  display: flex;
  align-items: center;
}
.title {
  display: flex;
  align-items: center;
  margin-right: 38px;
}

.ps-capitalize {
  text-transform: capitalize;
  color: blue;
  font-weight: bold;
}
.ps-compare-price {
  text-transform: capitalize;
  color: blue;
}

.new-navset {
  list-style: none;
}
.new-navset.show .nav-link::after {
  vertical-align: middle;
  display: none;
}
.new-navset a {
  color: white;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  text-decoration: none !important;
}
.new-navset a:hover {
  color: #fff;
}
.navbar.navbar-expand-lg.navbar-light.bg-header {
  z-index: 9999;
}

.new-navset .nav-link::after {
  font-size: 18px;
  top: 3px;
  position: relative;
}
.new-navset .nav-link {
  color: #fff;
  vertical-align: middle;
  font-weight: 600;
}

.new-navset .dropdown-menu {
  position: absolute;
  left: -90px;
  height: auto;
  padding: 0;
}
.new-navset .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #ccc;
  padding: 12px 10px;
}
.new-navset.show::before {
  content: "";
  border-bottom: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-top: 0;
  border-left: 0.3em solid transparent;
  bottom: 15px;
  right: 0px;
  font-size: 18px;
  position: absolute;
  color: #fff;
}
.item-count {
  font-weight: bold;
  font-size: 14px;
  vertical-align: middle;
  color: #ff0000;
}
.for-store {
  color: #ff0000;
  font-size: 14px;
  vertical-align: middle;
}

.buyers-guide-link {
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

/* carosule css */

.prevnextbtntren {
  position: absolute;
  left: 0px;
  top: 200px;
}
.prevnextbtndis {
  position: absolute;
  left: 0px;
  top: 600px;
}

.css-y5rags-Card {
  max-width: 400px !important;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 246px;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 40px;
  height: 80px;
  margin-right: 6px !important;
}
.prev {
  margin-left: -8px;
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 420px) {
  .css-y5rags-Card {
    width: 100% !important;
  }
}

.prev1,
.next1 {
  cursor: pointer;
  position: absolute;
  top: 146px;
  /* width: auto; */
  margin-top: 480px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 40px;
  height: 80px;
  margin-right: -20px !important;
}
.prev1 {
  margin-left: -6px;
}
.next1 {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 28px;
}
.prev2,
.next2 {
  cursor: pointer;
  position: absolute;
  top: 965px;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 40px;
  height: 80px;
  margin-right: 6px !important;
}
.prev2 {
  margin-left: -8px;
}
.next2 {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 3px;
}

.prev5,
.next5 {
  cursor: pointer;
  position: absolute;
  top: 146px;
  /* width: auto; */
  margin-top: 900px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 40px;
  height: 80px;
  margin-right: -20px !important;
}
.prev5 {
  margin-left: -6px;
}
.next5 {
  right: 0;
  border-radius: 3px 0 0 3px;
  right: 28px;
}

.css-1kfwsuu-resultStats {
  visibility: hidden;
}

@media only screen and (max-width: 768px) and (min-width: 580px) {
  .css-12v9vzr-container .css-149suws-Card {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 1%;
    max-width: 50% !important;
  }
}

.marginBottom .css-149suws-Card {
  max-width: 300px !important;
  /* margin: 4px 0px !important;
   */
  margin: 4px 4px !important;
}
/* login user flow */
.loginUser {
  margin-left: 263px;
}
@media (min-width: 992px) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 20% !important;
  }
}
@media (min-width: 800px) {
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 -106%;
    max-width: 24% !important;
  }
}
.bs-popover-bottom{
  margin-left: 100px !important;
}
.popover{
  width: 180px;
}
@media (min-width: 768px) {
  .popover {
    z-index: 9999 !important;
   transform: translate3d(1143px, 65px, 0px) !important;
  }
}
.popover-header{
  border-bottom: 1px solid #c5c3c3 !important;
  padding: 15px !important;
}
/* .popover .arrow{
  margin: 0px 3.3rem !important;
} */

.breadcrumb {
  background-color: white !important;
}

.css-149suws-Card {
  max-width: 333px !important;
  min-width: 240px !important;
  margin: 30px !important;
  height: 360px !important;
}

.mt-4 {
  margin-top: 0px !important;
}

.mx-5 {
  margin-right: 0px;
  margin-left: 0px;
}
.css-1kfwsuu-resultStats {
  display: none;
}

.HomePageView {
  margin-left: -4px !important;
}

/* vefify email input */
.MuiInput-underline:after {
  border-bottom: red !important;
}
/* all pop up models */
.Model {
  margin-top: 85px;
}
.was-validated .form-control:valid {
  background-size: 0px !important;
}

.ReactiveList {
  margin-left: 40px;
}
.sortBtn {
  margin-left: 170px !important;
}

.css-1w1pnf0-UL {
  padding-right: 9px !important;
}
@media (min-width: 1200px) {
  .col-xl-3 {
    max-width: 24% !important;
  }
}

.css-1bd5zqh-Checkbox-vh + label > span > span:nth-of-type(2) {
  margin-right: 10px !important ;
  color: #9b9b9b !important;
}
.bg-captcha {
  background-color: #fafaf6 !important;
}

/* otp password text divider "or" */
.text-divider {
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}
.detailPageImg{
  width: 100%;
}
.no-bullets {
  list-style-type: none;
  padding: 0;
}

/* Optional: Add some margin or padding to the list items if needed */
.no-bullets li {
  margin-bottom: 5px;
}
.listItem{
  border-bottom: solid !important;
}
.css-12v9vzr-container{
  /* justify-content: flex-start !important; */
  margin: 0 !important;
}

.table thead th {
  font-weight: normal !important;
}
.dottedBorder{
  border-bottom-style: dotted;
}
.checkbox{
  width: 23px;
  height: 23px;
}
.CheckboxUPIinput{
  margin-top: 120px;
}
@media (min-width:768px) {
  .withdrawOtp{
    display: flex;
  }
}
.earningsTitle{
  margin-top: 10px;
  margin-left: 12px;
}
@media (min-width: 768px) {
  .pagination {
    position: absolute;
    bottom: 150px;
    left: 90%;
    transform: translateX(-50%);
  }
}
@media (max-width: 768px) {
  .pagination {
    position: absolute;
    /* bottom: 1px; */
    left: 50%;
    transform: translateX(-50%);
  }
}
.slick-prev{
  left: 0px !important;
  z-index: 1111;
}
.slick-next{
  right: 4px !important;
  z-index: 1111 !important;

}

.css-nxp0ay-IconWrapper-ButtonIconWrapper {
  display: none !important;

}
/* .css-p4tgvk-sortOptions{
  display: none !important;
} */